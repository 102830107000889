export const MonsterTypes = {
  normal: "normal",
  spicy: "spicy",
  veggie: "veggie",
  fungi: "fungi",
  chill: "chill",
};

export const Monsters = {
  s001: {
    name: "BERA Samurai",
    description: "The OG BERA Monster",
    type: MonsterTypes.spicy,
    src: "/images/characters/monsters/s001.png",
    icon: "/images/icons/spicy.png",
    actions: ["burningStatus", "damage1", "saucyStatus"],
  },
  s002: {
    name: "Benny Brigade",
    description: "A salty warrior who fears nothing",
    type: MonsterTypes.spicy,
    src: "/images/characters/monsters/s002.png",
    icon: "/images/icons/spicy.png",
    actions: ["damage1", "saucyStatus", "clumsyStatus"],
  },
  s003: {
    name: "Funky Monkey",
    description: "A yellow monkey",
    type: MonsterTypes.spicy,
    src: "/images/characters/monsters/s003.png",
    icon: "/images/icons/spicy.png",
    actions: ["damage2", "saucyStatus"],
  },
  s004: {
    name: "Military Monkey",
    description: "A yellow monkey",
    type: MonsterTypes.chill,
    src: "/images/characters/monsters/s004.png",
    icon: "/images/icons/spicy.png",
    actions: ["damage1", "saucyStatus"],
  },
  s005: {
    name: "Artsy Monkey",
    description: "A artsey monkey",
    type: MonsterTypes.chill,
    src: "/images/characters/monsters/s005.png",
    icon: "/images/icons/spicy.png",
    actions: ["damage1", "saucyStatus"],
  },
  v001: {
    name: "Kale Monsta",
    description: "A BERA monster",
    type: MonsterTypes.veggie,
    src: "/images/characters/monsters/v001.png",
    icon: "/images/icons/veggie.png",
    actions: ["damage1"],
  },
  v002: {
    name: "Pepe Archie",
    description: "A BERA monster",
    type: MonsterTypes.veggie,
    src: "/images/characters/monsters/v002.png",
    icon: "/images/icons/veggie.png",
    actions: ["damage1"],
  },
  f001: {
    name: "Dogey",
    description: "A BERA monster",
    type: MonsterTypes.fungi,
    src: "/images/characters/monsters/f001.png",
    icon: "/images/icons/fungi.png",
    actions: ["damage1"],
  },
  f002: {
    name: "Monkee Killa",
    description: "A BERA monster",
    type: MonsterTypes.fungi,
    src: "/images/characters/monsters/f001.png",
    icon: "/images/icons/fungi.png",
    actions: ["damage1"],
  },
};
