import { Web3Provider, JsonRpcProvider } from "@ethersproject/providers";
import { parseEther } from "@ethersproject/units";
import { Contract } from "@ethersproject/contracts";

export class NoWalletError extends Error {
  constructor() {
    super('No wallet detected! Please install MetaMask.');
    this.name = 'NoWalletError';
  }
}

export class UserRejectedRequestError extends Error {
  constructor() {
    super('User rejected request.');
    this.name = 'UserRejectedRequestError';
  }
}

export class IncorrectChainError extends Error {
  constructor() {
    super('Please ensure your wallet is connected to Berachain');
    this.name = 'IncorrectChainError';
  }
}

export const getBlockHeight = async () => {
  const provider = new JsonRpcProvider(
    "https://bartio.rpc.berachain.com"
  );
  return provider.getBlockNumber();
}

class CryptoManager {
  static CONTRACT_ADDRESS = "0x024E09f473B7AD4c598042AE69D1aE4FEeDe93F4";
  static CONTRACT_ABI = [
    {
      inputs: [{ internalType: "uint256", name: "id", type: "uint256" }],
      name: "claimNFT",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "delegate",
      outputs: [],
      stateMutability: "payable",
      type: "function",
    },
    {
      inputs: [],
      name: "fight",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [],
      name: "getRewards",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "referral",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "referer", type: "address" }],
      name: "registerAcc",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "swapVal", type: "uint256" }],
      name: "swap",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "uint256", name: "_amount", type: "uint256" }],
      name: "undelegate",
      outputs: [],
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "userActions",
      outputs: [
        { internalType: "uint256", name: "swap", type: "uint256" },
        { internalType: "uint256", name: "fights", type: "uint256" },
        { internalType: "uint256", name: "delegates", type: "uint256" },
        { internalType: "uint256", name: "claims", type: "uint256" },
      ],
      stateMutability: "view",
      type: "function",
    },
    {
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "userDelegates",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      stateMutability: "view",
      type: "function",
    },
  ];

  constructor() {
    this.provider = null;
    this.signer = null;
    this.contract = null;
    this.isInitialized = false;
  }

  checkHasWallet() {
    if (typeof window.ethereum === "undefined") {
      throw new NoWalletError();
    }
  }

  async checkReadyForTransaction() {
    if (!this.isInitialized) await this.init();
    this.checkHasWallet();
    await this.checkChainId();
  }

  async checkChainId() {
    try {
      const { chainId } = await this.provider.getNetwork();
      if (chainId !== 80084) {
        throw new IncorrectChainError();
      }
    } catch {
      throw new IncorrectChainError();
    }
  }

  async addBerachain() {
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: "0x138d4",
          rpcUrls: ["https://bartio.rpc.berachain.com/"],
          chainName: "Berachain Artio",
          nativeCurrency: {
            name: "BERA",
            symbol: "BERA",
            decimals: 18,
          },
          blockExplorerUrls: ["https://bartio.beratrail.io/"],
        },
      ],
    });
  }

  async init() {
    this.checkHasWallet();

    try {
      await this.checkChainId();
    } catch {
      await this.addBerachain();
    }
    
    this.provider = new Web3Provider(window.ethereum);
    this.signer = this.provider.getSigner();
    
    this.contract = new Contract(
      CryptoManager.CONTRACT_ADDRESS,
      CryptoManager.CONTRACT_ABI,
      this.signer
    );
    this.isInitialized = true;
  }

  async getConnectedAddresses() {
    if (typeof window.ethereum === "undefined") {
      return [];
    }
    const accounts = await window.ethereum.request({method: 'eth_accounts'});
    console.log(`accounts: ${accounts}`)
    return accounts;
  }

  async getActiveAddress() {
    await this.checkReadyForTransaction();
    return await this.signer.getAddress();
  }

  async registerAccount(refererAddress) {
    await this.checkReadyForTransaction();
    try {
      return await this.contract.registerAcc(refererAddress);
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        throw new UserRejectedRequestError();
      } else {
        throw error;
      }
    }
  }

  async isRegistered(address) {
    await this.checkReadyForTransaction();
    const res = await this.contract.referral(address);
    return res !== "0x0000000000000000000000000000000000000000";
  }

  async swap(swapVal) {
    await this.checkReadyForTransaction();
    return await this.contract.swap(swapVal);
  }

  async delegate(amount) {
    await this.checkReadyForTransaction();
    return await this.contract.delegate({
      value: parseEther(amount.toString()),
    });
  }

  async undelegate(amount) {
    await this.checkReadyForTransaction();
    return await this.contract.undelegate(amount);
  }

  async claimNFT(id) {
    await this.checkReadyForTransaction();
    return await this.contract.claimNFT(id);
  }

  async getRewards() {
    await this.checkReadyForTransaction();
    return await this.contract.getRewards();
  }

  async getUserActions(address) {
    await this.checkReadyForTransaction();
    return await this.contract.userActions(address);
  }

  async getUserDelegates(address) {
    await this.checkReadyForTransaction();
    return await this.contract.userDelegates(address);
  }

  setupAccountChangeListener() {
    window.ethereum.on('accountsChanged', async (accounts) => {
      console.log("Changed account")  
      if (accounts.length === 0) {
            window.location.reload();
        } else {
            if (this.isInitialized) {
              this.signer = this.provider.getSigner();
            }
            window.location.reload();
        }
    });
  }
}

export const cryptoManager = new CryptoManager();
