export class HudAddress {
  constructor(address) {
      this.walletAddress = address;
      this.element = null;
  }

  createElement() {
      this.element = document.createElement("div");
      this.element.classList.add("Hudaddress");

      this.element.innerHTML = this.walletAddress ? `
          <p class="Hudaddress_name" onclick="navigator.clipboard.writeText('${
              this.ethAddress
          }')">${this.walletAddress.substring(
              0,
              5
          )}...${this.walletAddress.substring(
              this.walletAddress.length - 4,
              this.walletAddress.length
          )}</p>
      ` : `<p class="Hudaddress_name" >Wallet not connected</p>`;

      return this.element;
  }

  init(container) {
      const element = this.createElement();
      container.appendChild(element);
  }
}